"use client";

import { useAppSelector } from "hooks/useReducer";
import Link from "next/link";
import HeaderMenu from "./HeaderMenu";

export default function HeaderLoginButton() {
  const { isLogin } = useAppSelector((state) => state.user);

  return (
    <>
      {/* login ui */}
      {isLogin === true && (
        <div className="flex items-center">
          <HeaderMenu />
        </div>
      )}

      {/* logout ui */}
      {isLogin === false && (
        <div className="flex flex-row">
          <Link
            className="text-sm mx-1 p-3 py-2 font-semibold hover:opacity-80"
            href="/account/signup"
          >
            회원가입
          </Link>
          <Link
            className="text-sm mx-1 p-3 py-2 font-semibold border border-white hover:opacity-80 rounded-md"
            href="/account/signin"
          >
            로그인
          </Link>
        </div>
      )}
    </>
  );
}
