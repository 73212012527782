"use client";

import { updateIsLogin, updateProfile, updateUser } from "features/userSlice";

import { deleteCookie } from "cookies-next";
import api from "lib/api";
import { StudentMeProfileResponse } from "lib/api/student.type";
import ga from "lib/log";
import { useAppDispatch } from "./useReducer";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  const login = () => {
    getProfile();
    dispatch(updateIsLogin(true));
    dispatch(
      updateUser({
        name: "email",
        value: "비어있음",
      })
    );
  };

  const logout = async () => {
    // await serverRemoveToken();
    deleteCookie("accessToken");

    dispatch(updateIsLogin(false));
  };

  const getProfile = async () => {
    api.getStudentMeProfile().then((res) => {
      const result = res.data.userDto;
      result.birth = result.birth.split("T")[0];
      dispatch(updateProfile(result));
      setGAUserProfile(res);
    });
  };

  const setGAUserProfile = (data: StudentMeProfileResponse) => {
    const { birth, email, gender, id } = data.data.userDto;
    ga.set({ birth, email, gender, id });
  };

  return { login, logout, getProfile };
};
