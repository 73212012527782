"use client";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useAuth } from "hooks/useAuth";
import { useAppSelector } from "hooks/useReducer";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState } from "react";

export interface HeaderProps {
  type?: undefined | "normal" | "simple";
}

const MENU_ITEMS = [
  { title: "내 진단검사", href: "/my/assessment/" },
  { title: "바우처 내역", href: "/my/coupon/" },
  { title: "내 정보", href: "/my/info/" },
];

export default function HeaderMenu() {
  const { user } = useAppSelector((state) => state.user);
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const { logout } = useAuth();
  const onOpenMenu = () => setMenuOpen(true);
  const onCloseMenu = () => setMenuOpen(false);

  const handleLogout = () => {
    logout();
    router.push("/");
  };
  return (
    <div
      className="flex"
      onMouseOver={onOpenMenu}
      onMouseEnter={onOpenMenu}
      onMouseOut={onCloseMenu}
      onMouseLeave={onCloseMenu}
    >
      <div className="flex flex-row items-center mx-1 cursor-pointer hover:opacity-80 header-h">
        <AccountCircleIcon sx={{ fontSize: "20px", color: "#e5e9ed" }} />
        <div className="text-sm mx-1 font-semibold">{user.name} 님</div>
        <ArrowDropDownIcon sx={{ fontSize: "22px", ml: "-4px" }} />
      </div>
      {menuOpen && (
        <>
          <div className="bg-white border border-gray-300 absolute header-menu-top text-black rounded-b-md right-0 w-[140px] pt-3 pb-4">
            {MENU_ITEMS.map((item) => (
              <div key={item.title}>
                <Link
                  href={item.href}
                  onClick={() => {
                    // ReactGA.send({
                    //   hitType: "button",
                    //   page: "/my-path",
                    //   title: "Custom Title",
                    // });
                  }}
                >
                  <HeaderMenuItem title={item.title} />
                </Link>
              </div>
            ))}
            <div className="border-t border-gray-300" onClick={handleLogout}>
              <HeaderMenuItem title="로그아웃" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function HeaderMenuItem({ title }: { title: string }) {
  return (
    <div className="py-3 pl-5 border-b border-gray-100 text-sm hover:bg-gray-100 duration-200 cursor-pointer">
      {title}
    </div>
  );
}
