"use client";

import dynamic from "next/dynamic";

const HeaderBackButton = dynamic(
  () => import("./components/HeaderBackButton"),
  {
    ssr: false,
  }
);

export default function SimpleHeaderServer() {
  return (
    <>
      <div className="bg-gray-200 fixed w-full top-0 left-0 h-[50px] flex items-center border-b border-[#e9effe] z-10">
        <HeaderBackButton />
      </div>
      <div className="mt-[50px]" />
    </>
  );
}
