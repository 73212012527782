"use client";

import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import HeaderLoginButton from "../../HeaderLoginButton";

export default function DefaultHeaderClient() {
  const [isScrolled, setIsScrolled] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (pathname === "/") {
        if (currentScrollY > 50) {
          // 50px 이상 스크롤되면 배경색 변경
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      } else {
        // 다른 페이지에서는 항상 그라데이션 배경 유지
        setIsScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // 초기 상태 설정

    return () => window.removeEventListener("scroll", handleScroll);
  }, [pathname]);

  return (
    <>
      <Box
        sx={(theme) => ({
          position: "fixed",
          width: "100%",
          top: 0,
          left: 0,
          zIndex: 10,
          ...(pathname === "/"
            ? {
                bgcolor: isScrolled
                  ? {
                      background: theme.palette.primary.gradient,
                    }
                  : "#1d2736",
              }
            : {
                background: `linear-gradient(to left, ${theme.palette.primary.background}, ${theme.palette.secondary.background})`,
              }),
        })}
      >
        <div className="max-w-[1280px] max-h-full w-full mx-auto px-6">
          <div className="text-white flex justify-between header-h items-center relative">
            <Link className="relative w-[105px] h-[50px]" href="/">
              <Image
                src="/logo/logo-light-beta3.svg"
                alt="logo light"
                fill
                sizes="(max-width: 640px) 100px, 200px"
                className="object-contain"
              />
            </Link>
            <HeaderLoginButton />
          </div>
        </div>
      </Box>
      {pathname === "/" && <div className="header-space" />}
    </>
  );
}
